import { Environment } from 'Environment';
import React, { JSXElementConstructor } from 'react';
import { CurrentUserStoreSelectors } from 'reactApp/modules/CurrentUserModule/CurrentUserStoreSelectors';
import { CryptoHelper } from 'utils/CryptoHelper';
import { useForceUpdate } from 'utils/hooks/LifecycleHooks';

const TEST_USERS = /^test(\.box|_login)[0-9A-z-+_.]+@(mail|inbox|list|bk)\.ru$/i;

const CORP_USERS = /^[0-9A-z-+_.]+@(corp|biz|mcs|cloud)\.mail\.ru$/i;

const AKBARS_USERS = ['akbars@mcs.mail.ru'];

const fnv32cache = Object.create(null);

// @ts-ignore (7006) FIXME: Parameter 'email' implicitly has an 'any' type.
function getCachedInt36Fnv32aHash(email) {
    let hash = fnv32cache[email];

    if (hash) {
        return hash;
    }

    hash = CryptoHelper.getInt36Fnv32aHash(email);
    fnv32cache[email] = hash;

    return hash;
}

/**
 * @param {Array.<string|RegExp>} patterns
 * @param {string} email
 * @returns {boolean}
 */
// @ts-ignore (7006) FIXME: Parameter 'patterns' implicitly has an 'any' type.
function match(patterns, email) {
    let isMatches = false;

    for (const pattern of patterns) {
        if (pattern instanceof RegExp) {
            isMatches = pattern.test(email);
        } else if (typeof pattern === 'string') {
            if (pattern.includes('@')) {
                isMatches = pattern === email;
            } else {
                isMatches = pattern === getCachedInt36Fnv32aHash(email);
            }
        }

        if (isMatches) {
            break;
        }
    }

    return isMatches;
}

function getCurrentUserEmail() {
    return CurrentUserStoreSelectors.email;
}

// @ts-ignore (7006) FIXME: Parameter 'segment' implicitly has an 'any' type.
function isUserInSegment(segment) {
    return match(segment, getCurrentUserEmail());
}

function isTestUser() {
    return isUserInSegment([TEST_USERS]);
}

function isCorpUser() {
    return isUserInSegment([CORP_USERS]);
}

function isAlphaUser() {
    return isUserInSegment([CORP_USERS]) || isUserInSegment([TEST_USERS]);
}

function getIsShadowPortAvailable() {
    return [
        'mcs1719704212',
        'mcs0581499237',
        'mcs9042817373',
        'mcs6765624926',
        'mcs9116215436',
        'mcs9990431700',
        'mcs3081954207',
        'mcs5586711677',
    ].includes(CurrentUserStoreSelectors.pid);
}

let knownFeatures = {
    get shadow_port() {
        return getIsShadowPortAvailable();
    },

    get ext_networks_hidden() {
        return true;
    },

    remove_container_disk: true,

    network_topology: true,

    get cdn() {
        return !Environment.isPrivateBuild;
    },

    get cdn_banned() {
        return false;
    },

    mcs_terraform_config: true,

    get grm() {
        return true;
    },

    get postgrespro() {
        return isAlphaUser() || isUserInSegment(['r1eilx', '1lf2pbk']);
    },

    get marketplace_test_bucket() {
        return !Environment.isProductionHost || isAlphaUser();
    },

    get promo_popup_disabled() {
        return isTestUser();
    },

    get monitoring() {
        return isAlphaUser();
    },

    get dpp() {
        return !isUserInSegment(['1xlu0tn', '1w16qxr', '1gdbmbi', 'rw4c85']);
    },

    get akbars_marketplace() {
        return isUserInSegment(AKBARS_USERS);
    },

    get disk_type_dropdown() {
        const targetPids = [
            'mcs8573445583',
            'mcs1811799105',
            'mcs2403517943',
            'mcs8715937436',
        ];
        const pid = CurrentUserStoreSelectors.pid;

        return targetPids.includes(pid);
    },

    get horizon() {
        const targetPids = ['mcs4442639231'];
        const pid = CurrentUserStoreSelectors.pid;

        return targetPids.includes(pid);
    },

    get container_log() {
        return true;
    },

    get video_help_guides() {
        return true;
    },

    get main_page_banners() {
        return true;
    },

    get auto_open_notifications() {
        return false;
    },

    get sqs() {
        return Environment.isStagingHost;
    },

    get all_big_data_template() {
        return false;
    },

    get stpd_dnd() {
        return isAlphaUser();
    },

    get action_logger() {
        return (
            Environment.isDevelopmentRun ||
            Environment.isDevelopmentHost ||
            Environment.isStagingHost
        );
    },

    get shown_client_button_pay() {
        return isTestUser();
    },

    get create_big_data() {
        return Environment.isPrivateBuild;
    },

    get create_arenadata_trial() {
        return false;
    },

    get new_magnum_orchestrator() {
        return 'new_magnum_orchestrator_disabled';
    },

    get create_share_ssd() {
        return false;
    },

    get dns() {
        return false;
    },

    get create_port_for_octavia() {
        return Environment.isPrivateBuild;
    },

    get hide_inn_dialog() {
        return false;
    },

    get user_scripts() {
        return 'user_scripts';
    },

    get monitoring_by_default() {
        return 'monitoring_by_default_disabled';
    },

    get arenadata_hadoop() {
        return isAlphaUser();
    },

    get billing_instance_grouping() {
        return Environment.isStagingHost || Environment.isPrivateBuild;
    },

    get ignore_max_volume_size() {
        return 'ignore_max_volume_size';
    },

    get new_karbor_api() {
        return false;
    },

    get voice() {
        return false;
    },

    get database_params() {
        return false;
    },

    get new_notifications_backend() {
        return isAlphaUser();
    },

    get service_manager() {
        return isAlphaUser();
    },

    get new_scrooge_api() {
        return isAlphaUser();
    },
};

const listenerActions = new Set<() => void>();

function connector<
    TOwnProps extends {},
    TComponent extends JSXElementConstructor<TOwnProps>
>(Component: TComponent) {
    return function FeaturesWrapper(props: TOwnProps) {
        useFeatures();

        // @ts-ignore (2322) FIXME: Type 'TOwnProps' is not assignable to type 'Intrin... Remove this comment to see the full error message
        return <Component {...props} />;
    };
}

function useFeatures() {
    const forceUpdate = useForceUpdate();

    React.useEffect(() => {
        listenerActions.add(forceUpdate);
        return () => {
            listenerActions.delete(forceUpdate);
        };
    }, []);
}

export type FeatureName = keyof typeof knownFeatures;

export const features = {
    isEnabled(featureName: FeatureName) {
        return Boolean(knownFeatures[featureName]);
    },

    getFeatureVariant(featureName: FeatureName) {
        return knownFeatures[featureName];
    },

    // @ts-ignore (7006) FIXME: Parameter 'flags' implicitly has an 'any' type.
    setFeatureFlags(flags, notifyListeners = true) {
        knownFeatures = flags;

        if (notifyListeners) {
            [...listenerActions].forEach((action) => action());
        }
    },
    knownFeatures,
    connector,
    useFeatures,
    isTestUser,
    isCorpUser,
    isAlphaUser,
};

export default features;
