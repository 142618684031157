import { Environment } from 'Environment';
import moment from 'moment';
import { setLang, setLocale } from 'tx-i18n';
import { Lang } from 'utils/LangHelper';

const loadLocale = async (lang: Lang) => {
    const langToLocaleId = {
        // need also to include
        en: 'en_US',
    };

    const localeId = lang !== 'ru' && langToLocaleId[lang];

    if (localeId) {
        const locale = await import(`locale/${localeId}`);
        setLocale(localeId, locale.default);
        setLang(localeId);
    }
};

const loadMomentLocale = async (lang: Lang) => {
    const langToMomentLocale = {
        en: 'en-gb',
        ru: 'ru',
        // when add new languages, remember to add them into context replacement plugin
        // new webpack.ContextReplacementPlugin(/moment[/\\]locale$/, /ru|en-gb/),
    };
    const momentLocale = langToMomentLocale[lang];

    await import(`moment/locale/${momentLocale}`);

    moment.locale(momentLocale);
};

export const setupLocalisation = async (lang = Environment.lang) => {
    await Promise.all([loadLocale(lang), loadMomentLocale(lang)]);
};
