import { StateHelper } from 'utils/StateHelper';

const INITIAL_STATE = {
    token: '',
};

export const CsrfTokenModule = StateHelper.createModuleState(
    'CsrfToken',
    INITIAL_STATE,
);
