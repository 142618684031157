import { AuthorisationModuleInterface } from 'reactApp/modules/Authorisation/AuthorisationModule';
import activeRoute from 'reactApp/reducers/activeRoute';
import { combineReducers } from 'redux';
import { StateHelper } from 'utils/StateHelper';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateModulesState extends AuthorisationModuleInterface {}

export interface GlobalState {
    activeRoute: any;

    // [stateHelper.REDUCER_NAME]: StateModulesState;
    // don't know how to fix TS1169 error, worked in other TS version well
    // temp fix:
    stateModules: StateModulesState;
}

const rootReducer = combineReducers({
    activeRoute,
    [StateHelper.REDUCER_NAME]: StateHelper.reducer,
});

export default rootReducer;
