import rootReducer, { GlobalState } from 'reactApp/core/rootReducer';
import {
    applyMiddleware,
    compose,
    createStore as createReduxStore,
} from 'redux';
// @ts-ignore (7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { Action } from 'redux-actions';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { flushUsedNames } from 'utils/StateHelper';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

const middlewares = [thunk];

function configureStoreProd(initialStore = {}) {
    return createReduxStore<GlobalState, Action<any>, ThunkMiddleware, any>(
        // @ts-ignore (2345) FIXME: Argument of type 'Reducer<{ [x: string]: ...; acti... Remove this comment to see the full error message
        rootReducer,
        initialStore,
        compose(applyMiddleware(...middlewares)),
    );
}

function configureStoreDev(initialStore = {}) {
    const composeEnhancers =
        (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
    const store = createReduxStore<
        GlobalState,
        Action<any>,
        ThunkMiddleware,
        any
    >(
        // @ts-ignore (2345) FIXME: Argument of type 'Reducer<{ [x: string]: ...; acti... Remove this comment to see the full error message
        rootReducer,
        initialStore,
        composeEnhancers(applyMiddleware(...middlewares)),
    );

    if (module.hot) {
        module.hot.accept('reactApp/core/rootReducer', () => {
            flushUsedNames();
            const imported = require('reactApp/core/rootReducer');
            store.replaceReducer(imported);
        });
    }

    return store;
}

export const createStore =
    process.env.NODE_ENV === 'production'
        ? configureStoreProd
        : configureStoreDev;
