import * as R from 'ramda';
import { ReduxTypeUtils } from 'utils/ReduxTypeUtils';

import { SET_ACTIVE_ROUTE } from '../actions/route';
import { GlobalState } from '../core/rootReducer';

// @ts-ignore (7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export default function activeRoute(state, { type, data }) {
    if (!state) {
        return {
            /**
             * id роута, например: #storage-buckets
             * @type {string}
             */
            id: '',

            /**
             * Параметры роута роута, например: /storage/:bucket/:prefix
             * @type {object}
             */
            params: {},

            /**
             * Query-параметры запроса.
             * @type {object}
             */
            query: {},

            /**
             * Дополнительные данные роута, например:
             *
             *     router.nav('/storage/', details)
             *     или
             *     router.go('#storage-main', params, query, details)
             *
             * @type {object}
             */
            details: {},
        };
    }

    if (type === SET_ACTIVE_ROUTE) {
        return {
            id: data.routeId,
            params: data.params,
            query: data.query,
            details: data.details,
        };
    }
    return state;
}

// @ts-ignore (7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getActiveRoute = (state) => state.activeRoute;
// @ts-ignore (2322) FIXME: Type '(x0: any) => unknown' is not assignable to t... Remove this comment to see the full error message
export const getActiveRouteId: ReduxTypeUtils.Selector<string> = R.pipe(
    getActiveRoute,
    R.prop('id'),
);
// @ts-ignore (7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getActiveRouteParams = (state) => state.activeRoute.params;
export const getActiveRouteTabParam = R.pipe(
    getActiveRouteParams,
    (data: { tab: string }) => data.tab,
);
// @ts-ignore (7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getActiveRouteDetails = (state) => state.activeRoute.details || {};
export const getQuery = <T = Record<string, string>>(state: GlobalState) =>
    state.activeRoute.query as T;
