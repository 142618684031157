import { ReduxHelper } from 'utils/ReduxHelper';

import { CsrfTokenModule } from './CsrfTokenModule';

// @ts-ignore (7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function getToken(state) {
    return CsrfTokenModule.getModuleState(state).token;
}

export const CsrfTokenSelectrors = {
    getToken,
};

export const BoundCsrfTokenSelectrors = ReduxHelper.bindSelectors(
    CsrfTokenSelectrors,
);
