import * as R from 'ramda';
import { GlobalState } from 'reactApp/core/rootReducer';
import { RamdaExtender } from 'utils/RamdaExtender';

interface Action<State> {
    type: string;
    payload: {
        moduleName: string;
        moduleKey?: string;
        moduleState?: State;
        initialState?: State;
        partName?: string;
        partData?: any; // TODO: remove any
    };
}

const createReducer = <StateGeneric = StringMap>(actionPrefix: string) => {
    return (state: StateGeneric, action: Action<StateGeneric>) => {
        const resultState = {
            ...(state || {}),
        } as StateGeneric;

        if (action && action.type.indexOf(actionPrefix) === 0) {
            const {
                payload: {
                    moduleKey,
                    moduleState,
                    initialState,
                    partName,
                    partData,
                },
            } = action;
            const moduleName = action.payload.moduleName as keyof StateGeneric;

            if (moduleKey !== undefined) {
                const multimoduleState = resultState[moduleName] || ({} as any);

                if (moduleState) {
                    multimoduleState[moduleKey] = { ...moduleState };
                    resultState[moduleName] = multimoduleState;
                } else {
                    multimoduleState[moduleKey] = {
                        ...(multimoduleState[moduleKey] || initialState),
                        // @ts-ignore (2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
                        [partName]: partData,
                    };
                    resultState[moduleName] = multimoduleState;
                }
            } else if (moduleState) {
                // @ts-ignore (2322) FIXME: Type 'StateGeneric' is not assignable to type 'Sta... Remove this comment to see the full error message
                resultState[moduleName] = { ...moduleState };
            } else {
                // @ts-ignore (2322) FIXME: Type '(StateGeneric & {}) | (StateGeneric[keyof St... Remove this comment to see the full error message
                resultState[moduleName] = {
                    ...(state[moduleName] || initialState),
                    // @ts-ignore (2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
                    [partName]: partData,
                };
            }
        }

        return resultState;
    };
};

interface HelperCreatorParams {
    actionPrefix: string;
    reducerName: string;
}
interface ConstructorParams<StateGeneric extends StringMap>
    extends HelperCreatorParams {
    name: string;
    key?: string;
    initialState: StateGeneric;
}
export class ModuleState<
    StateGeneric extends StringMap,
    GlobalStateGeneric extends StringMap
> {
    _moduleName: string;
    _moduleKey: string | undefined;
    _initialState: StateGeneric;
    _reducerName: string;
    _actionPrefix: string;

    constructor({
        name,
        key,
        initialState,
        reducerName,
        actionPrefix,
    }: ConstructorParams<StateGeneric>) {
        this._moduleName = name;
        this._moduleKey = key;
        this._initialState = initialState;
        this._reducerName = reducerName;
        this._actionPrefix = actionPrefix;
    }

    setPart = <T extends keyof StateGeneric>(
        name: T,
        data: StateGeneric[T],
    ) => {
        return {
            type: `${this._actionPrefix}_${this._moduleName}_${name}`, // data after prefix in not important, just for better debugging
            payload: {
                moduleName: this._moduleName,
                moduleKey: this._moduleKey,
                initialState: this._initialState,
                partName: name,
                partData: data,
            },
        };
    };

    getPart = <T extends keyof StateGeneric>(
        state: GlobalStateGeneric,
        name: T,
    ): StateGeneric[T] extends never | never[] ? any : StateGeneric[T] => {
        const stateHelperState = this.getModuleState(state);
        const moduleState = stateHelperState[name];
        return RamdaExtender.cloneNotDeep(moduleState);
    };

    setModuleState = (state: StateGeneric) => {
        return {
            type: `${ACTION_PREFIX}_${this._moduleName}`,
            payload: {
                moduleName: this._moduleName,
                moduleKey: this._moduleKey,
                moduleState: state,
            },
        };
    };

    mergeModuleState = (state: Partial<StateGeneric>) => (
        // @ts-ignore (7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
        dispatch,
        // @ts-ignore (7006) FIXME: Parameter 'getState' implicitly has an 'any' type.
        getState,
    ) => {
        const moduleState = this.getModuleState(getState());
        const mergedState = R.merge(moduleState, state);

        dispatch(this.setModuleState(mergedState));
    };

    getModuleState = (state: GlobalStateGeneric): StateGeneric => {
        const module = {
            ...this._initialState,
            ...R.pathOr({}, [this._reducerName, this._moduleName], state),
        };

        if (this._moduleKey != null) {
            return module[this._moduleKey] || this._initialState || {};
        }
        return module;
    };

    makePartGetter = <P extends keyof StateGeneric & string>(
        name: P,
    ): ((state: GlobalStateGeneric) => StateGeneric[P]) => {
        return R.pipe(this.getModuleState, R.prop(name));
    };
}

let _usedNames: string[] = [];
const _checkNameUsage = (name: string) => {
    if (_usedNames.includes(name)) {
        throw new Error(
            `StateHelper module with name "${name}" already exists. Should have unique name for each module.`,
        );
    } else {
        _usedNames.push(name);
    }
};
export function flushUsedNames() {
    _usedNames = [];
}

const ACTION_PREFIX = 'STATE_MODULE';

const REDUCER_NAME = 'stateModules';

export const StateHelper = {
    createModuleState<StateGeneric>(name: string, initialState: StateGeneric) {
        _checkNameUsage(name);

        return new ModuleState<StateGeneric, GlobalState>({
            name,
            initialState,
            reducerName: REDUCER_NAME,
            actionPrefix: ACTION_PREFIX,
        });
    },
    getMultiModule<StateGeneric>({
        name,
        initialState,
        key,
    }: {
        name: string;
        initialState: StateGeneric;
        key: any;
    }) {
        if (typeof key === 'object') {
            key = JSON.stringify(key);
        }
        return new ModuleState<StateGeneric, GlobalState>({
            name,
            key,
            initialState,
            reducerName: REDUCER_NAME,
            actionPrefix: ACTION_PREFIX,
        });
    },
    REDUCER_NAME,
    reducer: createReducer(ACTION_PREFIX),
};
