/* eslint-disable no-console */

import { CryptoHelper } from 'utils/CryptoHelper';

const xPageId = CryptoHelper.getBase58Id(8);

try {
    console.log(`x-page-id: ${xPageId}`);
} catch (reason) {
    // Do nothing
}

export default xPageId;
