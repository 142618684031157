import { CryptoHelper } from 'utils/CryptoHelper';

export const BASE58 =
    '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
export const SPECIAL_SYMBOLS = '!\\"#$%&()*+,-.:;<=>?@[]^_`{}~';

export type ChartGroup = {
    chars: string;
    length: number;
};

export const RandomHelper = {
    getRandomInt: (min = 0, max = 100) =>
        Math.floor(min + Math.random() * (max + 1 - min)),

    getCryptoRandomInt: (min = 0, max = 100) => {
        if (!window.crypto) {
            return RandomHelper.getRandomInt(min, max);
        }

        const intArray = new Uint32Array(1);
        const random = window.crypto.getRandomValues(intArray)[0] / 2 ** 32;
        return Math.floor(min + random * (max + 1 - min));
    },

    generateString: (length: number, chars = BASE58) => {
        let result = '';
        for (let i = 0; i < length; i++) {
            const rnum = RandomHelper.getCryptoRandomInt(0, chars.length - 1);
            result += chars.substring(rnum, rnum + 1);
        }

        return result;
    },

    generateStringWithGroups(groups: ChartGroup[]) {
        const result = groups.reduce((result, { chars, length }) => {
            return result + this.generateString(length, chars);
        }, '');

        return RandomHelper.shuffle(result);
    },

    shuffle(str: string) {
        const array = str.split('');
        for (let i = array.length - 1; i > 0; i--) {
            const j = RandomHelper.getCryptoRandomInt(0, i);

            [array[i], array[j]] = [array[j], array[i]];
        }

        return array.join('');
    },

    generateHours() {
        return String(RandomHelper.getRandomInt(0, 23)).padStart(2, '0');
    },

    generateMinutes() {
        return String(RandomHelper.getRandomInt(0, 59)).padStart(2, '0');
    },

    generateWeekday() {
        return String(RandomHelper.getRandomInt(0, 6));
    },

    jigurda(suffix: string, email: string, limit: number) {
        const crc = CryptoHelper.calcCRC(email + suffix);

        return crc >= 0 && crc < limit * 10;
    },
};
