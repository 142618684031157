import { DPP } from 'utilities/DPP/DPP';

/**
 * @param {ErrorEvent|PromiseRejectionEvent} event
 */
function onError(event) {
    if (
        event.type === 'error' &&
        !/\/app\.mcs\.st\/.*?\/static\/.*?\/app\/.*?\.js$/.test(event.filename)
    ) {
        return;
    }

    if (
        event.type === 'unhandledrejection' &&
        !(event.reason instanceof Error)
    ) {
        return;
    }

    const payload = {
        error: event.error || event.reason || event.message,
        filename: event.filename,
        lineno: event.lineno,
        colno: event.colno,
        type: event.type,
    };

    DPP.sendEvent({ type: 'error', payload });
}

window.addEventListener('error', onError);
window.addEventListener('unhandledrejection', onError);
