import * as R from 'ramda';
import { GlobalState } from 'reactApp/core/rootReducer';
import { Action, ActionCreatorsMapObject } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector, defaultMemoize } from 'reselect';
import { getStore } from 'store/store';
import { ArrayHelper } from 'utils/ArrayHelper';
import { HttpStatusHelper } from 'utils/HttpStatusHelper/HttpStatusHelper';
import { ReduxTypeUtils } from 'utils/ReduxTypeUtils';
import { ModuleState } from 'utils/StateHelper';

import { ThunkResult } from './ReduxTypeUtils/ReduxTypeUtils';

type MakeCreateActionProps<T, Item> = {
    module: ModuleState<T, GlobalState>;
    apiMethod: (...args: any[]) => Promise<Item>;
    entityName: string;
    beforeCreate?: (...args: any[]) => any | any[];
    idKey?: string;
    argumentIdGetter?: (arg: any) => any;
    loadItemOnRemoveAction?: (id: any) => Promise<void>;
    listKey?: string;
    handleSuccess?: (item: Item) => ThunkResult<Promise<Item>>;
    afterCreate?: (item: Item) => void;
    insertToStart?: boolean;
};

// @ts-ignore (7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const memoizeByState = <T extends (state, ...args) => any>(f: T) => {
    // @ts-ignore (7034) FIXME: Variable 'prevState' implicitly has type 'any' in ... Remove this comment to see the full error message
    let prevState;
    // @ts-ignore (7034) FIXME: Variable 'cached' implicitly has type 'any' in som... Remove this comment to see the full error message
    let cached;

    return function(state) {
        // @ts-ignore (7005) FIXME: Variable 'prevState' implicitly has an 'any' type.
        if (prevState !== state) {
            prevState = state;
            cached = undefined;
        }

        // @ts-ignore (7005) FIXME: Variable 'cached' implicitly has an 'any' type.
        if (cached === undefined) {
            // @ts-ignore (2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
            // eslint-disable-next-line prefer-rest-params
            cached = f.apply(this, arguments);
        }

        // @ts-ignore (7005) FIXME: Variable 'cached' implicitly has an 'any' type.
        return cached;
    } as T;
};

export const ReduxHelper = {
    memoizeByState,
    // @ts-ignore (7019) FIXME: Rest parameter 'creatorArguments' implicitly has a... Remove this comment to see the full error message
    createSelectorWithArguments: (...creatorArguments) => {
        const selectors = [...creatorArguments];
        const resultFunc = selectors.pop();

        // @ts-ignore (7019) FIXME: Rest parameter 'selectorsResult' implicitly has an... Remove this comment to see the full error message
        const selectorResultFunc = (...selectorsResult) =>
            // @ts-ignore (7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
            defaultMemoize((...args) =>
                resultFunc(...selectorsResult, ...args),
            );

        const selector = createSelector(
            ...selectors,
            // @ts-ignore (2556) FIXME: Expected 2-13 arguments, but got 1 or more.
            selectorResultFunc,
        );

        // @ts-ignore (7006) FIXME: Parameter 'state' implicitly has an 'any' type.
        return (state, ...args) => selector(state)(...args);
    },
    bindActions: <T extends ActionCreatorsMapObject>(actions: T) =>
        // @ts-ignore (2769) FIXME: No overload matches this call.
        (R.map((action) => {
            // @ts-ignore (7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
            return (...args) => getStore().dispatch(action(...args));
        }, actions) as any) as {
            [P in keyof T]: CutMiddleFunction<T[P]>;
        },
    bindSelectors: <S extends StringMap>(selectors: S) =>
        // @ts-ignore (2769) FIXME: No overload matches this call.
        R.map((selector) => {
            // @ts-ignore (7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
            return (...args) => selector(getStore().getState(), ...args);
        }, selectors) as RemoveFirstArgumentOfObjectProps<S>,
    // @ts-ignore (7031) FIXME: Binding element 'actions' implicitly has an 'any' ... Remove this comment to see the full error message
    bindModule: ({ actions, selectors }) => {
        return {
            actions: ReduxHelper.bindActions(actions),
            selectors: ReduxHelper.bindSelectors(selectors),
        };
    },
    // @ts-ignore (7031) FIXME: Binding element 'onChange' implicitly has an 'any'... Remove this comment to see the full error message
    subscribeOnSelector({ onChange, boundSelector }) {
        // @ts-ignore (7034) FIXME: Variable 'currentState' implicitly has type 'any' ... Remove this comment to see the full error message
        let currentState;

        function handleChange() {
            const nextState = boundSelector();
            // @ts-ignore (7005) FIXME: Variable 'currentState' implicitly has an 'any' ty... Remove this comment to see the full error message
            if (nextState !== currentState) {
                currentState = nextState;
                onChange(currentState);
            }
        }

        const unsubscribe = getStore().subscribe(handleChange);
        handleChange();
        return unsubscribe;
    },

    createLoadListAction<T extends BasicModuleState, List>({
        module,
        apiMethod,
        entityName,
        fieldKey = 'list',
    }: {
        module: ModuleState<T, GlobalState>;
        apiMethod: (...args: any[]) => Promise<List>;
        entityName: string;
        fieldKey?: string;
    }) {
        // @ts-ignore (7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
        return (...args) => async (dispatch) => {
            dispatch(
                // @ts-ignore (2345) FIXME: Argument of type '{ isLoaded: false; isLoading: tr... Remove this comment to see the full error message
                module.mergeModuleState({
                    isLoaded: false,
                    isLoading: true,
                    hasError: false,
                }),
            );

            try {
                const data = await apiMethod(...args);

                dispatch(
                    // @ts-ignore (2345) FIXME: Argument of type '{ [x: string]: boolean | List; i... Remove this comment to see the full error message
                    module.mergeModuleState({
                        [fieldKey]: data,
                        isLoaded: true,
                        isLoading: false,
                        hasError: false,
                    }),
                );

                return data;
            } catch (error) {
                dispatch(
                    // @ts-ignore (2345) FIXME: Argument of type '{ hasError: true; isLoaded: fals... Remove this comment to see the full error message
                    module.mergeModuleState({
                        hasError: true,
                        isLoaded: false,
                        isLoading: false,
                    }),
                );

                console.error(`${entityName} ${fieldKey} loading error`, error);

                throw error;
            }
        };
    },

    // @ts-ignore (7031) FIXME: Binding element 'loadList' implicitly has an 'any'... Remove this comment to see the full error message
    createLoadListSavingStateAction({ loadList, updateList, module }) {
        // @ts-ignore (7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
        return (...args) => async (dispatch, getState) => {
            if (
                module.getPart(getState(), 'isLoaded') &&
                !module.getPart(getState(), 'hasError')
            ) {
                return dispatch(updateList(...args));
            }
            return dispatch(loadList(...args));
        };
    },

    createLoadListSavingStateActionOnly<T extends BasicModuleState, List>({
        module,
        apiMethod,
        entityName,
        fieldKey = 'list',
        entityNamePublic = '',
    }: {
        module: ModuleState<T, GlobalState>;
        apiMethod: (...args: any[]) => Promise<List>;
        entityName: string;
        fieldKey?: string;
        entityNamePublic?: string;
    }) {
        const initialLoadListAction = ReduxHelper.createLoadListAction({
            module,
            apiMethod,
            entityName,
            fieldKey,
        });

        const updateListAction = ReduxHelper.createUpdateListAction({
            module,
            apiMethod,
            entityName,
            entityNameLocal: entityNamePublic,
            fieldKey,
        });

        return ReduxHelper.createLoadListSavingStateAction({
            loadList: initialLoadListAction,
            updateList: updateListAction,
            module,
        });
    },

    // @ts-ignore (7031) FIXME: Binding element 'loadList' implicitly has an 'any'... Remove this comment to see the full error message
    createLoadListIfNotLoadedAction({ loadList, module, fieldKey = 'list' }) {
        // @ts-ignore (7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
        return (...args) => async (dispatch, getState) => {
            if (!module.getPart(getState(), 'isLoaded')) {
                await dispatch(loadList(...args));
            }

            return module.getPart(getState(), fieldKey);
        };
    },

    createLoadItemAction({
        // @ts-ignore (7031) FIXME: Binding element 'module' implicitly has an 'any' t... Remove this comment to see the full error message
        module,
        // @ts-ignore (7031) FIXME: Binding element 'apiMethod' implicitly has an 'any... Remove this comment to see the full error message
        apiMethod,
        // @ts-ignore (7031) FIXME: Binding element 'entityName' implicitly has an 'an... Remove this comment to see the full error message
        entityName,
        // @ts-ignore (7031) FIXME: Binding element 'entityNamePublic' implicitly has ... Remove this comment to see the full error message
        entityNamePublic,
        idKey = 'id',
        listFieldKey = 'list',
    }) {
        // @ts-ignore (7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
        return (...args) => async (dispatch, getState) => {
            try {
                const item = await apiMethod(...args);

                dispatch(
                    module.mergeModuleState({
                        [listFieldKey]: ArrayHelper.getListWithInsertedItem({
                            item,
                            list: module.getPart(getState(), listFieldKey),
                            idKey,
                        }),
                    }),
                );

                return item;
            } catch (error) {
                console.error(`${entityName} loading error`, error);
                throw `Не удалось загрузить ${entityNamePublic} ${args[0]}`;
            }
        };
    },

    createUpdateListAction({
        // @ts-ignore (7031) FIXME: Binding element 'module' implicitly has an 'any' t... Remove this comment to see the full error message
        module,
        // @ts-ignore (7031) FIXME: Binding element 'apiMethod' implicitly has an 'any... Remove this comment to see the full error message
        apiMethod,
        // @ts-ignore (7031) FIXME: Binding element 'entityName' implicitly has an 'an... Remove this comment to see the full error message
        entityName,
        // @ts-ignore (7031) FIXME: Binding element 'entityNameLocal' implicitly has a... Remove this comment to see the full error message
        entityNameLocal,
        fieldKey = 'list',
    }) {
        // @ts-ignore (7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
        return (...args) => async (dispatch) => {
            try {
                const data = await apiMethod(...args);

                dispatch(
                    module.mergeModuleState({
                        [fieldKey]: data,
                    }),
                );
                return data;
            } catch (error) {
                console.error(`${entityName} loading error`, error);
                throw `Не удалось загрузить ${entityNameLocal}`;
            }
        };
    },

    // @ts-ignore (7031) FIXME: Binding element 'apiMethod' implicitly has an 'any... Remove this comment to see the full error message
    createEntityListLoader: ({ apiMethod, modulePropName, module }) => (
        // @ts-ignore (7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
        ...args
    ) =>
        // @ts-ignore (7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
        async (dispatch) => {
            let entityList;

            try {
                entityList = await apiMethod(...args);
            } catch (error) {
                console.error(`${modulePropName} loading error`, error);
                throw error;
            }

            dispatch(module.mergeModuleState({ [modulePropName]: entityList }));

            return entityList;
        },

    makeCreateAction<T extends BasicModuleState, Item>({
        module,
        apiMethod,
        entityName,
        beforeCreate,
        handleSuccess,
        afterCreate,
        idKey = 'id',
        listKey = 'list',
        insertToStart,
    }: MakeCreateActionProps<T, Item>) {
        // @ts-ignore (7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
        return (...args) => async (
            dispatch: ThunkDispatch<GlobalState, null, Action>,
            // @ts-ignore (7006) FIXME: Parameter 'getState' implicitly has an 'any' type.
            getState,
        ) => {
            let handledArgs = args;
            if (beforeCreate) {
                handledArgs = beforeCreate(...args);
                // @ts-ignore (2769) FIXME: No overload matches this call.
                handledArgs = [].concat(handledArgs);
            }

            let item: Item;
            try {
                item = await apiMethod(...handledArgs);
                console.warn(item);
            } catch (error) {
                console.error(`can't create ${entityName}`, error);

                throw error;
            }

            const { notCallAfterCreate = false } = args[0];
            if (!notCallAfterCreate && afterCreate) {
                afterCreate(item);
            }

            if (handleSuccess) {
                return dispatch(handleSuccess(item));
            }

            if (item) {
                dispatch(
                    module.setPart(
                        listKey as any,
                        ArrayHelper.getListWithInsertedItem({
                            list: module.getPart(getState(), listKey as any),
                            item,
                            idKey,
                            insertToStart,
                        }),
                    ),
                );
            }

            return item;
        };
    },

    makeRemoveAction<T extends BasicModuleState, K = any>({
        module,
        apiMethod,
        entityName,
        entityNamePublic,
        beforeRemove,
        idKey = 'id',
        argumentIdGetter = R.identity,
        loadItemOnRemoveAction,
        listKey = 'list',
        noErrorHandling,
        handleError = (error) => {
            console.error(`can't remove ${entityName}`, error);
            error.toString = () => `Не удалось удалить ${entityNamePublic}`;
            throw error;
        },
    }: {
        module: ModuleState<T, GlobalState>;
        apiMethod: (...args: K[]) => Promise<void>;
        entityName?: string;
        entityNamePublic?: string;
        beforeRemove?: (...args: any[]) => void;
        idKey?: string;
        argumentIdGetter?: (arg: any) => any;
        loadItemOnRemoveAction?: (
            id: any,
        ) => (dispatch: any, getState: any) => Promise<void>;
        listKey?: string;
        noErrorHandling?: boolean;
        handleError?: (error: any) => any;
    }) {
        // @ts-ignore (7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
        return (...args: K[]) => async (dispatch, getState) => {
            if (beforeRemove) {
                beforeRemove(...args);
            }

            try {
                await apiMethod(...args);
            } catch (error) {
                if (noErrorHandling) {
                    throw error;
                }
                return handleError(error);
            }

            const id = argumentIdGetter(args[0]);

            if (loadItemOnRemoveAction) {
                await dispatch(loadItemOnRemoveAction(id));
            } else {
                dispatch(
                    module.setPart(
                        listKey as any,
                        ArrayHelper.getListWithoutItem({
                            list: module.getPart(getState(), listKey as any),
                            id,
                            idKey,
                        }),
                    ),
                );
            }
        };
    },

    makeEditAction<
        Item,
        UpdateItemData = Partial<Item>,
        M extends ModuleState<any, any> = ModuleState<any, any>,
        RestParams extends Array<any> = any[]
    >({
        module,
        apiMethod,
        idKey = 'id',
        listKey = 'list',
        onItemEdited = R.identity,
    }: {
        module: M;
        apiMethod: (...args: any[]) => Promise<Item>;
        idKey?: string;
        listKey?: string;
        onItemEdited?: (...args: any[]) => any;
    }) {
        return (
            id: any,
            data: UpdateItemData,
            ...args: RestParams
        ): ReduxTypeUtils.ThunkResult<Promise<Item>> => async (
            dispatch,
            getState,
        ) => {
            const item = await apiMethod(id, data, ...args);

            dispatch(
                module.setPart(
                    listKey,
                    ArrayHelper.getListWithInsertedItem({
                        list: module.getPart(getState(), listKey),
                        idKey,
                        item,
                        id,
                    }),
                ),
            );

            await dispatch(onItemEdited());

            return item;
        };
    },

    // @ts-ignore (7006) FIXME: Parameter 'action' implicitly has an 'any' type.
    multiplyAction<T = any>(action) {
        // @ts-ignore (7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
        return (ids, ...args) => async (dispatch) => {
            const failedIds: any[] = [];
            const failedErrors: any[] = [];

            const result = await Promise.all<T>(
                // @ts-ignore (7006) FIXME: Parameter 'id' implicitly has an 'any' type.
                ids.map(async (id) => {
                    try {
                        return await dispatch(action(id, ...args));
                    } catch (error) {
                        if (!HttpStatusHelper.isNotFound(error)) {
                            failedIds.push(id);
                            failedErrors.push(error);
                        }
                    }
                }),
            );

            if (failedIds.length) {
                throw {
                    failedIds,
                    failedErrors,
                };
            }

            return result;
        };
    },

    makeStandardSelectors<Item>(
        // @ts-ignore (7006) FIXME: Parameter 'module' implicitly has an 'any' type.
        module,
        { idKey = 'id', listKey = 'list' } = {},
    ) {
        const getList = (state: GlobalState) =>
            module.getPart(state, listKey) as Item[];
        // @ts-ignore (7006) FIXME: Parameter 'id' implicitly has an 'any' type.
        const getItem = (state: GlobalState, id) =>
            // @ts-ignore (7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            // eslint-disable-next-line eqeqeq
            getList(state).find((item) => item[idKey] == id);
        const getIsLoading = (state: GlobalState) =>
            module.getPart(state, 'isLoading') as boolean;
        const getIsLoaded = (state: GlobalState) =>
            module.getPart(state, 'isLoaded') as boolean;
        const getHasError = (state: GlobalState) =>
            module.getPart(state, 'hasError') as boolean;

        return {
            getList,
            getItem,
            getIsLoading,
            getIsLoaded,
            getHasError,
        };
    },

    // @ts-ignore (7006) FIXME: Parameter 'listGetter' implicitly has an 'any' typ... Remove this comment to see the full error message
    createObjItemGetter: (listGetter) => (state, id) => {
        return R.pipe(
            listGetter,
            R.prop(id),
            // @ts-ignore (2554) FIXME: Expected 0 arguments, but got 1.
        )(state);
    },

    createParallelActionsCaller: (
        actions: any[],
        noReduxActions: (() => any)[] = [],
        // @ts-ignore (7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
    ) => () => async (dispatch) => {
        return Promise.all(
            actions
                .map((action) => dispatch(action()))
                .concat(noReduxActions.map((action) => action())),
        );
    },
};
