import { AxiosError } from 'axios';
import R from 'ramda';

export enum HttpStatus {
    Ok = 200,
    Created = 201,
    Accepted = 202,
    NoContent = 204,
    NotModified = 304,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Conflict = 409,
    NotAllowed = 405,
    TooManyRequests = 429,
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504,
}
type ResponseError = {
    status: HttpStatus;
};

const checkStatus = (status: HttpStatus, code: HttpStatus) => {
    return Boolean(status === code);
};

const checkErrorOrStatus = (
    status: HttpStatus,
    errorOrStatus: ResponseError | AxiosError | HttpStatus,
) => {
    let statusFromError = 0;

    if (typeof errorOrStatus === 'number') {
        statusFromError = errorOrStatus;
    }

    if (typeof errorOrStatus === 'object') {
        if ('isAxiosError' in errorOrStatus && errorOrStatus.isAxiosError) {
            statusFromError = errorOrStatus.response?.status || 0;
        }

        if ('status' in errorOrStatus) {
            statusFromError = errorOrStatus.status;
        }
    }

    return checkStatus(statusFromError, status);
};

const checkErrorOrStatusCurry = R.curry(checkErrorOrStatus);

export const HttpStatusHelper = {
    isNotFound: checkErrorOrStatusCurry(HttpStatus.NotFound),
    isUnauthorized: checkErrorOrStatusCurry(HttpStatus.Unauthorized),
    isForbidden: checkErrorOrStatusCurry(HttpStatus.Forbidden),
    isConflict: checkErrorOrStatusCurry(HttpStatus.Conflict),
    isBadRequest: checkErrorOrStatusCurry(HttpStatus.BadRequest),
    isNotAllowed: checkErrorOrStatusCurry(HttpStatus.NotAllowed),
    isTooManyRequests: checkErrorOrStatusCurry(HttpStatus.TooManyRequests),
};
