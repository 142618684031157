import * as R from 'ramda';

// @ts-ignore (7006) FIXME: Parameter 'prop' implicitly has an 'any' type.
const propUnEq = (prop, value) => (obj) => !R.propEq(prop, value)(obj);

// @ts-ignore (7006) FIXME: Parameter 'a' implicitly has an 'any' type.
const includes = (a, list) => list.includes(a);

// @ts-ignore (7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
const argsToArr = (...args) => args;

// @ts-ignore (7006) FIXME: Parameter 'f' implicitly has an 'any' type.
const negateFunc = (f) => (...args) => !f(...args);

// @ts-ignore (7006) FIXME: Parameter 'some' implicitly has an 'any' type.
const cloneNotDeep = (some) => {
    switch (true) {
        case Array.isArray(some):
            return [...some];
        case typeof some === 'object' && some !== null:
            return { ...some };
        default:
            return some;
    }
};

const mergeWithMultipleObjects = <T>(
    fn: (args: any[]) => any,
    [first, ...restObjects]: T[],
): T => {
    if (!restObjects.length) {
        return first;
    }

    return R.mergeWith(fn, first, mergeWithMultipleObjects(fn, restObjects));
};

export const RamdaExtender = {
    propUnEq,
    includes,
    argsToArr,
    negateFunc,
    cloneNotDeep,
    mergeWithMultipleObjects,
};
