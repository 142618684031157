import { DependencyList, useEffect, useRef, useState } from 'react';

export const useComponentDidMount = (fn: AnyFunction) => {
    useEffect(fn, []);
};

export const useComponentWillUnmount = (
    fn: AnyFunction,
    deps: DependencyList = [],
) => {
    useEffect(() => fn, deps);
};

export const useComponentWillMount = (func: AnyFunction) => {
    const willMount = useRef(true);

    useComponentDidMount(() => {
        willMount.current = false;
    });

    if (willMount.current) {
        return func();
    }
};

export const useEffectOnUpdate = (fn: AnyFunction, deps?: DependencyList) => {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            return fn();
        }
    }, deps);
};

export function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useForceUpdate() {
    const [, setValue] = useState(0); // integer state
    return () => setValue((value) => ++value); // update the state to force render
}
