import { BoundCsrfTokenSelectrors } from 'reactApp/modules/CsrfTokenModule/CsrfTokenModule.selectors';
import { CurrentUserStoreSelectors } from 'reactApp/modules/CurrentUserModule/CurrentUserStoreSelectors';
import { CryptoHelper } from 'utils/CryptoHelper';
import { v4 as uuidv4 } from 'uuid';
import xPageId from 'xPageId';
// @ts-ignore (7034) FIXME: Variable 'xTabId' implicitly has type 'any' in som... Remove this comment to see the full error message
import xTabId from 'xTabId';

type NextLink = {
    rel: string;
    href: string;
};

function reloadIfProjectOrUserBlocked(
    response?: { message?: string; error?: string },
    url?: string,
) {
    const isAuthorised = url !== '/api/v1/auth/signin';
    const isDisabled =
        response?.error === 'ACCESS_DENIED' &&
        response.message &&
        ['Project disabled', 'User disabled'].includes(response.message);

    if (isAuthorised && isDisabled) {
        window.location.reload();
    }
}

function getCommonRequestHeaders(): Record<string, string> {
    const token = BoundCsrfTokenSelectrors.getToken();

    return {
        [HEADER_KEYS.X_CSRF_TOKEN]: token,

        [HEADER_KEYS.CACHE_CONTROL]: 'no-cache',

        [HEADER_KEYS.X_REQ_ID_HEADER]: CryptoHelper.getBase58Id(8),
        [HEADER_KEYS.X_MCS_REQUEST_ID]: uuidv4().replace(/-/g, ''),
        [HEADER_KEYS.X_EMAIL]: CurrentUserStoreSelectors.email,
        // @ts-ignore (7005) FIXME: Variable 'xTabId' implicitly has an 'any' type.
        [HEADER_KEYS.X_TAB_ID]: xTabId,
        [HEADER_KEYS.X_PAGE_ID]: xPageId,
    };
}

function headersToObject(headers: Response['headers']) {
    const obj = {};
    headers.forEach((value, key) => {
        // @ts-ignore (7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        obj[key] = value;
    });
    return obj;
}

const loadFullList = async <
    Response,
    ListDataKey extends keyof Response,
    LinksDataKey extends keyof Response
>({
    loadFn,
    listDataKey,
    linksDataKey,
    params,
}: {
    loadFn({
        marker,
    }: {
        marker?: string;
        [key: string]: unknown;
    }): Promise<Response>;
    listDataKey?: ListDataKey;
    linksDataKey?: LinksDataKey;
    params?: Record<string, unknown>;
}) => {
    type ReturnOfLoadFn = AsyncFuncResult<typeof loadFn>;
    let nextLink: { rel: string; href: string } | undefined;
    // @ts-ignore FIXME: Type 'never[]' is not assignable to type 'Response extends any[] ? Response : Response[ListDataKey] extends any[] ? Response[ListDataKey] : any[]'.
    const items: ReturnOfLoadFn extends Array<any>
        ? ReturnOfLoadFn
        : ReturnOfLoadFn[ListDataKey] extends Array<any>
        ? ReturnOfLoadFn[ListDataKey]
        : any[] = [];

    do {
        const query = new URLSearchParams(nextLink?.href?.match(/\?.+/)?.[0]);
        const marker = query.get('marker');

        const data = await loadFn({
            ...params,
            ...(marker && { marker }),
        });

        if (Array.isArray(data)) {
            items.push(...data);
            break;
        }

        if (!listDataKey) {
            throw new Error('listDataKey not specified');
        }

        // @ts-ignore FIXME: TS2488: Type 'Response[ListDataKey]' must have a '[Symbol.iterator]()' method that returns an iterator.
        items.push(...data[listDataKey]);

        // @ts-ignore FIXME: TS2339: Property 'find' does not exist on type 'NonNullable '.
        nextLink = data[linksDataKey]?.find(
            (link: NextLink) => link.rel === 'next',
        );
    } while (nextLink);
    return items;
};

const HEADER_KEYS = {
    X_CSRF_TOKEN: 'x-csrf-token',

    CACHE_CONTROL: 'Cache-Control',

    X_REQ_ID_HEADER: 'x-req-id',
    X_MCS_REQUEST_ID: 'X-MCS-Request-Id',
    X_EMAIL: 'x-email',
    X_TAB_ID: 'x-tab-id',
    X_PAGE_ID: 'x-page-id',
};

export const ApiUtils = {
    reloadIfProjectOrUserBlocked,
    getCommonRequestHeaders,
    headersToObject,
    HEADER_KEYS,
    loadFullList,
};
