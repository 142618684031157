// file to include into any package (right now web app and landings)
// should be the only place to contain all shared start and initialization logic
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'services/system/main';
import 'whatwg-fetch';

import { Environment } from 'Environment';

window.Sentry && window.Sentry.setTag('lang', Environment.lang);
