/* eslint-disable no-console */

import { CryptoHelper } from 'utils/CryptoHelper';

// eslint-disable-next-line import/no-mutable-exports
let xTabId;

try {
    xTabId = sessionStorage.getItem('x-tab-id');
} catch (reason) {
    // Do nothing
}

if (!xTabId) {
    xTabId = CryptoHelper.getBase58Id(8);

    try {
        sessionStorage.setItem('x-tab-id', xTabId);
    } catch (reason) {
        // Do nothing
    }
}

try {
    console.log(`x-tab-id: ${xTabId}`);
} catch (reason) {
    // Do nothing
}

export default xTabId;
