import { ApiUtils } from 'api/ApiUtils';
import { AxiosError } from 'axios';
import { Environment } from 'Environment';
// @ts-ignore (7016) FIXME: Could not find a declaration file for module 'jque... Remove this comment to see the full error message
import $ from 'jquery';
import * as R from 'ramda';
import { CurrentUserStoreSelectors } from 'reactApp/modules/CurrentUserModule/CurrentUserStoreSelectors';
import features from 'system/features';
import { DPPEventType, ErrorType } from 'utilities/DPP/DPP.types';
import { JsonSafe } from 'utils/JsonSafe';
import { RandomHelper } from 'utils/RandomHelper';
import xPageId from 'xPageId';
// @ts-ignore (7034) FIXME: Variable 'xTabId' implicitly has type 'any' in som... Remove this comment to see the full error message
import xTabId from 'xTabId';

const dppUrl = '/infra/dpp/';
const namespace = 'mcs:front';

const sendEvent = async ({ type, payload, error }: DPPEventType) => {
    try {
        if (getIsMuted()) {
            return false;
        }
        let event: any = {};

        event = {
            email: CurrentUserStoreSelectors.email,
            pid: CurrentUserStoreSelectors.pid,
            roles: CurrentUserStoreSelectors.roles.join(','),
            location: window.location.href,
            version: process.env.COMMIT,
            // @ts-ignore (7005) FIXME: Variable 'xTabId' implicitly has an 'any' type.
            xTabId,
            xPageId,
            tag: getUserTag(),
            ...payload,
        };

        if (error) {
            event = {
                ...event,
                ...getReqIds(error),
                message: getErrorMessage(error),
                status:
                    payload && 'status' in payload
                        ? payload.status
                        : getErrorStatus(error),
                // @ts-ignore (7005) FIXME: Variable 'xTabId' implicitly has an 'any' type.
                eventId: `${xPageId}_${xTabId}_${RandomHelper.getRandomInt(
                    10000,
                )}`,
            };
        }

        const data = {
            timestamp: getISOString(),
            event_type: `${namespace}:${type}`,
            event: prepareData(event),
        };

        $.ajax({
            retryLimit: 1,
            type: 'POST',
            url: dppUrl,
            data: JsonSafe.stringify(data),
            contentType: 'application/json',
            headers: { 'Cache-Control': 'no-cache' },
        });
    } catch (error) {
        console.warn('dpp error by send event', error);
    }
};

function getReqIds(error: ErrorType) {
    if (typeof error !== 'string' && isAxiosError(error)) {
        return {
            reqId: error.config.headers[ApiUtils.HEADER_KEYS.X_REQ_ID_HEADER],
            mcsReqId:
                error.config.headers[ApiUtils.HEADER_KEYS.X_MCS_REQUEST_ID],
            openStackReqId: error.config.headers['x-openstack-request-id'],
        };
    }

    return {};
}

function getUserTag() {
    if (features.isTestUser()) {
        return 'test';
    }

    if (features.isCorpUser()) {
        return 'corp';
    }

    return 'lead';
}

function getIsMuted() {
    return (
        Environment.isPrivateBuild ||
        Environment.isAutoTesting ||
        Environment.isDevelopmentRun ||
        !features.isEnabled('dpp')
    );
}

// @ts-ignore (7006) FIXME: Parameter 'data' implicitly has an 'any' type.
function prepareData(data) {
    // DPP принимает только плоский объект с key-value в виде строк
    return R.map((value) => {
        if (typeof value === 'object') {
            return JSON.stringify(value);
        }

        return String(value);
    }, data);
}

function getISOString() {
    const date = new Date();
    return date.toISOString();
}

function isAxiosError(error: Exclude<ErrorType, string>): error is AxiosError {
    return 'isAxiosError' in error;
}

function getErrorMessage(error: ErrorType) {
    if (typeof error === 'string') {
        return error;
    }

    if (isAxiosError(error)) {
        return error.response?.data;
    }

    if ('responseText' in error && error.responseText) {
        return error.responseText;
    }

    if ('message' in error && error.message) {
        return error.message;
    }

    if ('status' in error && error.status === 0) {
        return 'Не дождались выполнения запроса или запрос был прерван';
    }

    return JsonSafe.stringify(error);
}

function getErrorStatus(error: ErrorType) {
    if (typeof error !== 'string') {
        if (isAxiosError(error)) {
            return error.response?.status;
        }

        // XMLHttpRequest
        if ('status' in error) {
            return error.status;
        }

        // AWSError
        if ('statusCode' in error) {
            return error.statusCode;
        }

        // Error
        return 'unknown';
    }

    return 'unknown';
}

export const DPP = {
    sendEvent,
    getUserTag,
};

/**
 * @overview DPP - data platform protocol
 * Система для логирования метрик, событий и ошибок.
 */
