import * as R from 'ramda';
// @ts-ignore (7016) FIXME: Could not find a declaration file for module 'semv... Remove this comment to see the full error message
import semver from 'semver';
import { JsonSafe } from 'utils/JsonSafe';
import { RandomHelper } from 'utils/RandomHelper';

export const ArrayHelper = {
    singleToArray<T>(singleOrArr: T | T[]) {
        return ([] as T[]).concat(singleOrArr);
    },

    uniq<T>(
        array: T[],
        getUniqKey: (item: T) => string | number = (item) =>
            JsonSafe.stringify(item),
    ) {
        const uniqArray: T[] = [];
        const uniqKeys = new Set();
        array.forEach((item) => {
            const uniqKey = getUniqKey(item);
            if (!uniqKeys.has(uniqKey)) {
                uniqArray.push(item);
                uniqKeys.add(uniqKey);
            }
        });

        return uniqArray;
    },

    getListWithInsertedItem<T>({
        list,
        item,
        idKey = 'id',
        idKeys = [idKey],
        id,
        insertToStart,
    }: {
        list: T[];
        item: T;
        idKey?: string | number | symbol;
        idKeys?: (string | number | symbol)[];
        id?: string;
        insertToStart?: boolean;
    }) {
        list = [...list];
        const index = list.findIndex((listItem) => {
            // @ts-ignore (7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            // eslint-disable-next-line eqeqeq
            return idKeys.every((key) => listItem[key] == (id || item[key]));
        });

        if (index === -1) {
            if (insertToStart) {
                list = [item, ...list];
            } else {
                list = [...list, item];
            }
        } else {
            list[index] = { ...item };
        }

        return list;
    },
    getListWithoutItem<T>({
        list,
        item,
        id,
        idKey = 'id',
    }: {
        list: T[];
        item?: T;
        id?: string;
        idKey?: string;
    }) {
        return list.filter(
            // @ts-ignore (7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            // eslint-disable-next-line eqeqeq
            (listItem) => listItem[idKey] != (id || item?.[idKey]),
        );
    },

    sortVersions<T extends { version: string }>(versions: T[]) {
        return versions.sort((a, b) => {
            return semver.lt(a.version, b.version) ? 1 : -1;
        });
    },

    listToObjectWithKeys<
        T extends Record<string, any> | string = Record<string, string>
    >(list: T[], keys: string[]) {
        return list.reduce<Record<string, T>>(
            (acc, item, index) => ({
                ...acc,
                [keys[index]]: item,
            }),
            {},
        );
    },

    listToObject<
        T extends Record<string, any> = Record<string, string>,
        K extends keyof T = keyof T
    >(list: T[]) {
        return list.reduce<Record<string, T[K]>>(
            (acc, item) => ({
                ...acc,
                ...item,
            }),
            {},
        );
    },

    getArrayOfSize<T>(
        n: number | string,
        filler: (() => T) | T | undefined = undefined,
    ): T[] {
        const arr = new Array(Number(n));
        arr.fill(filler);
        if (typeof filler === 'function') {
            return arr.map(filler as () => T);
        }
        return arr;
    },

    sortWithOrderedPredicates<T>(
        items: T[],
        orderedPredicates: ((arg: T) => boolean)[],
    ): T[] {
        // @ts-ignore (2339) FIXME: Property 'sorted' does not exist on type '(arg: T)... Remove this comment to see the full error message
        const { sorted, rest } = orderedPredicates.reduce(
            // @ts-ignore (2769) FIXME: No overload matches this call.
            ({ sorted, rest }, predicate) => {
                const matchedItems = rest.filter((item) => predicate(item));

                return {
                    sorted: [...sorted, ...matchedItems.sort()],
                    rest: R.without(matchedItems, rest),
                };
            },
            {
                sorted: [],
                rest: items,
            },
        );

        return sorted.concat(rest);
    },

    clearNullable<T>(array: T[]) {
        return array.filter(Boolean) as Exclude<
            T,
            false | undefined | null | 0 | ''
        >[];
    },

    getRandomItem<T>(array: T[]) {
        const randomIndex = RandomHelper.getRandomInt(0, array.length - 1);
        return array[randomIndex];
    },

    getArrayWithoutItemByIndex<T>(array: T[], index: number) {
        return array.slice(0, index).concat(array.slice(index + 1));
    },

    getIdMap<T>(array: T[], idKey = 'id') {
        return new Map<string | number, T>(
            // @ts-ignore (7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            array.map((item) => [item[idKey], item]),
        );
    },

    padEnd<T>(array: T[], minLength: number, fillValue?: T) {
        return Object.assign(new Array(minLength).fill(fillValue), array);
    },
};
