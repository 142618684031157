import './css-load-checher.css';
import './initApp';
import './initShared';

import { setupLocalisation } from 'localisationSetup';

const loadApp = () => {
    return import('./app');
};

window.__startApp__ = function() {
    return setupLocalisation()
        .then(loadApp)
        .catch(loadApp);
};

if (module.hot) {
    module.hot.accept();
}
